import React from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'
import { StaticImage } from 'gatsby-plugin-image'

const CabaretInfosPorteParole = () => (
    <CabaretLayout>
        <div className='cabaret-infos'>

            <CabaretInfosNavigation />

            <h1>Porte-Parole</h1>

            <div className='cabaret-infos-inner'>

                <h2 className='!lowercase'>comment debord</h2>
                <div className='text-center px-auto'>
                    <StaticImage src="../../../images/cabaret/porteparole.jpg" className='rounded-3xl my-6 max-w-[750px]' />
                </div>

                <h3>Ayant tourné aux quatre coins du Québec suite à la sortie de leur premier album en 2020, le groupe montréalais comment debord est de retour sur les planches avec un spectacle rock décontracté et rassembleur.<br/><br/>Sans compromis sur le groove, la bande explore sans complexe les textures chaleureuses de la musique des années soixante-dix, pigeant dans le folk et le rock alternatif contemporain, y apposant avec justesse des textes poétiques attachants. Participant chacun et chacune aux riches harmonies vocales qui caractérisent le projet, les musiciens et musiciennes savent s’écouter et se répondre, maîtrisant riffs accrocheurs et solos déjantés.  Préconisant la construction d’une ambiance festive qui se termine inévitablement en dancefloor, les guitares électriques, la basse, les claviers et les percussions sont au rendez-vous.</h3>

                <center>
                    <div className='mt-10'>
                        <a href="https://www.facebook.com/commentdebord">
                            <StaticImage
                                src="../images/cabaret/artists_detail/facebook.png"
                                alt="Facebook logo"
                                className="cabaret-candidat-detail-social" />
                        </a>
                        <a href="https://www.instagram.com/commentdebord/">
                            <StaticImage
                                src="../images/cabaret/artists_detail/instagram.png"
                                alt="Instagram logo"
                                className="cabaret-candidat-detail-social ml-4" />
                        </a>
                        <a href="Site web: https://commentdebord.com/">
                            <StaticImage
                                src="../images/cabaret/artists_detail/web.png"
                                alt="Generic web site logo"
                                className="cabaret-candidat-detail-social ml-4" />
                        </a>
                    </div>
                </center>

                <div className="mt-10 relative h-[35rem]">
                    <iframe
                        title="Sara Dufour - Semi-route Semi-trail"
                        className="absolute top-0 left-0 w-full h-full mb-3rem"
                        src={'https://www.youtube.com/embed/uJy4mAFod9s'}
                        allowFullScreen=""
                        referrerPolicy="origin-when-cross-origin"
                        height={'100%'}
                    ></iframe>
                </div>

            </div>

        </div>
    </CabaretLayout>
)

export default CabaretInfosPorteParole
